import * as globalEventsAction from "../actions/globalEvent.action"

const globalEvents = (
    state = {
        events: null,
        allEvents: null,
        womenDay: null,
        data: null,
        deviceId: null,
        oneEvent: null,
        selectedTopics: [],
        totalCount: 0,
    },
    action
) => {
    switch (action.type) {
        case globalEventsAction.ADD_TO_SELECTION:
            const updatedSelection = [...state.selectedTopics];
            const existingIndex = updatedSelection.findIndex(
                (item) => item.globalEventId === action.payload.globalEventId
            );

            if (existingIndex !== -1) {
                updatedSelection[existingIndex].quantity += 1;
            } else {
                updatedSelection.push(action.payload);
            }

            return {
                ...state,
                selectedTopics: updatedSelection,
            };

        case globalEventsAction.REMOVE_FROM_SELECTION:
            const reducedSelection = state.selectedTopics
                .map((item) =>
                    item.globalEventId === action.payload.globalEventId
                        ? { ...item, quantity: item.quantity - 1 }
                        : item
                )
                .filter((item) => item.quantity > 0);

            return {
                ...state,
                selectedTopics: reducedSelection,
            };

        case globalEventsAction.REMOVE_FROM_SELECTION: {
            const existingIndex = state.selectedTopics.findIndex(
                (item) => item.id === action.payload.id
            );

            let updatedTopics = [...state.selectedTopics];

            if (existingIndex !== -1) {
                const updatedItem = {
                    ...updatedTopics[existingIndex],
                    count: updatedTopics[existingIndex].count - 1,
                };

                if (updatedItem.count > 0) {
                    updatedTopics[existingIndex] = updatedItem;
                } else {
                    // Remove the topic if count is 0
                    updatedTopics.splice(existingIndex, 1);
                }
            }

            const totalCount = updatedTopics.reduce((sum, topic) => sum + topic.count, 0);

            return Object.assign({}, state, {
                selectedTopics: updatedTopics,
                totalCount: totalCount,
            });
        }
        
        case globalEventsAction.GET_GLOBAL_EVENTS_REQUEST:
            return Object.assign({}, state, {
                status: action.status,
                loading: true
            })

        case globalEventsAction.GET_GLOBAL_EVENTS_SUCCESS:
            return Object.assign({}, state, {
                status: action.status,
                loading: false,
                events: action.payload
            })

        case globalEventsAction.GET_GLOBAL_EVENTS_FAILURE:
            return Object.assign({}, state, {
                status: action.status,
                error: action.error,
            })

        case globalEventsAction.GET_WOMEN_DAY_REQUEST:
            return Object.assign({}, state, {
                status: action.status,
                loading: true
            })

        case globalEventsAction.GET_WOMEN_DAY_SUCCESS:
            return Object.assign({}, state, {
                status: action.status,
                loading: false,
                womenDay: action.payload
            })

        case globalEventsAction.GET_WOMEN_DAY_FAILURE:
            return Object.assign({}, state, {
                status: action.status,
                error: action.error
            })

        case globalEventsAction.GET_ALL_GLOBAL_EVENTS_REQUEST:
            return Object.assign({}, state, {
                status: action.status,
                loading: true
            })
        
        case globalEventsAction.GET_ALL_GLOBAL_EVENTS_SUCCESS:
            return Object.assign({}, state, {
                status: action.status,
                loading: false,
                allEvents: action.payload
            })

        case globalEventsAction.GET_ALL_GLOBAL_EVENTS_FAILURE:
            return Object.assign({}, state, {
                status: action.status,
                error: action.error,
            })

        case globalEventsAction.POST_USER_DATA_REQUEST:
            return Object.assign({}, state, {
                status: action.status,
                loading: true
            })    
           
        case globalEventsAction.POST_USER_DATA_SUCCESS:
            return Object.assign({}, state, {
                status: action.status,
                loading: false,
                statusCode: action.statusCode
            })

        case globalEventsAction.POST_USER_DATA_FAILURE:
            return Object.assign({}, state, {
                status: action.status,
                error: action.error
            })

        case globalEventsAction.POST_DEVICE_DETAILS_REQUEST:
            return Object.assign({}, state, {
                status: action.status,
                loading: true
            })

        case globalEventsAction.POST_DEVICE_DETAILS_SUCCESS:
            return Object.assign({}, state, {
                status: action.status,
                loading: false,
                deviceId: action.deviceId
            })

        case globalEventsAction.POST_DEVICE_DETAILS_FAILURE:
            return Object.assign({}, state, {
                status: action.status,
                error: action.error
            })

        case globalEventsAction.POST_CALENDAR_PAGE_REQUEST:
            return Object.assign({}, state, {
                status: action.status,
                loading: true
            })

        case globalEventsAction.POST_CALENDAR_PAGE_SUCCESS:
            return Object.assign({}, state, {
                status: action.status,
                loading: false,
                calendarData: action.payload
            })

        case globalEventsAction.POST_CALENDAR_PAGE_FAILURE:
            return Object.assign({}, state, {
                status: action.status,
                error: action.error
            })
            
        case globalEventsAction.GET_ONE_EVENT_REQUEST:
            return Object.assign({}, state, {
                status: action.status,
                loading: true
            })

        case globalEventsAction.GET_ONE_EVENT_SUCCESS:
            return Object.assign({}, state, {
                status: action.status,
                loading: false,
                oneEvent: action.payload
            })

        case globalEventsAction.GET_ONE_EVENT_FAILURE:
            return Object.assign({}, state, {
                status: action.status,
                error: action.error
            })
            
        default:
            return state
    }
}
export default globalEvents